.clients-slider-container {
    position: relative;
    text-align: center; 
    display: flex;
    flex-direction: column; 
    align-items: center; 
   
  
  }
  
  .clients-heading {
    font-size: 30px;
    font-weight: 100;
    font-family: sans-serif;
    margin-bottom: 50px;
    
  }
  .clients-slider-container p{
padding-bottom: 20px;
font-size: 18px;
  }
  .slider-row {
    display: flex;
    justify-content: center; 
    margin-bottom: 40px; 

  }
  

  
  .our-clients-item img {
    width: 140px; 
    height: 40px; 
    margin-right: 50px;
   background-size: contain;
  }
  
    
  .industry-box-item{
    width: 100%;
    height: 100%;
    border-radius: 7px;
    
  }
.block-heading{
  margin-top: 8rem;
  font-size: 30px;
  font-weight: 100;
  font-family: sans-serif;
  margin-bottom: 50px;
}
  .Fintech{
    background-image: url(https://relevant.software/img/video/home/industry-box/fintech/fintech_blk.webp);
    background-size: cover;
  }
  .Retail{
    background-image: url(https://relevant.software/img/video/home/industry-box/retail/retail_blk.jpg);
    background-size: cover;
  }
  .Real-Estate{
    background-image: url(https://relevant.software/img/video/home/industry-box/realestate/realestate.jpg);
    background-size: cover;
  }
  .Travel{
    background-image: url(https://relevant.software/img/video/home/industry-box/travel/travel_blk.jpg);
    background-size: cover;
  }
  .Construction{
    background-image: url(https://relevant.software/img/video/home/industry-box/construction/construction_blk.jpg);
    background-size: cover;
  }
  .front{
    font-size: 16px;
    font-weight: 200;
    margin-bottom: 18px;
    letter-spacing: -0.44px;
    padding: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 95%;
    color: white;
    font-family: Helvetica;
    line-height: 1.7;
  }
  
.front .heading {
font-size: 24px;
font-weight: 300;
margin-left: 30px;
}
  .service-where-we-differ {
padding-top: 30px;
padding-bottom: 50px;
  }
  
  .wrapper {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .block-heading.relevant {
    text-align: center; 
    margin-bottom: 40px; 
    font-size: 2.5rem; 
   color: #7d7c7c;
   font-family: sans-serif;
  }
  
  .service-where-we-differ-item {
    margin-bottom: 20px; 
  }
  
  .service-where-we-differ-item .heading {
    text-align: left; 
    font-weight: 500; 
    font-size: 28px; 
    color: black;
    line-height: 36px;
    margin-bottom: 8px;
  font-family:Helvetica;
  }
  
  .service-where-we-differ-item .description {
    text-align: left; 
    margin-top: 10px; 
    font-size: 20px; 
    color: #878686; 
    font-weight: 100;
    line-height: 32px;
      letter-spacing: -0.8px;
      font-family: Helvetica;
  }
  
  
  .call-form-container {
    border-radius: 30px;
    background-color: black; 
    color: white; 
    padding: 50px 20px;
    text-align: center; 
    max-width: 1200px; 
    margin: 0 auto;
  }
  
  .call-form-container h2 {
    margin-bottom: 40px;
    font-family: Helvetica;
    font-size: 42px;
font-weight: 400;
  }

  
  .call-form-team {
    display: flex;
    justify-content: center;
    flex-wrap: wrap; 
    gap: 40px; 
    margin-bottom: 40px; 
  }
  
  .call-form-team-member {
    text-align: center; 
    max-width: 346px; 
  }
  
  .call-form-team-member img {
    width: 100%; 

  }
  
  .call-form-team-member h3 {
    margin-top: 10px; 
    font-family: sans-serif;
  }
  
  .call-form-team-member p {
    margin-top: 5px;
    font-family: sans-serif;
    font-weight: 100;
    color: #c5c5c5;
  }
  
  .what-to-expect {
    text-align: left; 
    margin-bottom: 40px;
  }
  
  .call-form-cta {
    text-align: center; 
  }
  
  .call-form-btn {
    background-color: white; 
    color: black; 
    padding: 10px 30px; 
    border-radius: 5px; 
    text-decoration: none; 
    font-weight: bold; 
  }
  .call-form-team-member img{
    border-radius: 50%;
    width: 150px;
  height: 150px;
  } 