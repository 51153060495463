body {
    background-color: #f8f9fb;
    margin: 0;
  }
  
  .hero-section {
    background-position: right;
    background-size: 800px 700px;
    background-repeat: no-repeat;
    text-align: center;
    padding: 150px 0;
    background-image: url('../assets/home_bg.webp');
    height: 300px;
    width: 1200px;
    background-color: #f5f5f5;
    margin: 0 auto;
    margin-top: 110px;
   
  }
  
  .hero-content {
    max-width: 800px;
    text-align: left;
    padding-left: 40px;
    margin-bottom: 0%;
    padding-bottom: 0;
  }
  
  .hero-content h1 {
    font-size: 44px;
    font-weight: 500;
    color: black;
    font-family:Helvetica;
  }
  
  .hero-content p {
    font-size: 20px;
    font-weight: 400;
    color: #a9a9a9;
    font-family: sans-serif;
  }
  .new-box {
    background-color: white;
    margin: 0 auto;
    margin-top: 35px;
    height: 80px;
    width: 90%;
    border-radius: 10px;
    justify-content: space-between;
  }
  .image-container {
    display: flex; /* Use flexbox to layout the images */
    flex-wrap: wrap; /* Allow images to wrap to the next line */
    justify-content: space-between; /* Center align images */
    align-items: center; /* Center align images vertically */
    gap: 10px; /* Space between images */
    max-width: 1000px; /* Match the width of the hero section */
    margin-left: 40px;
    padding-top: 10px;
    
  }
  .image-container img {
    max-height: 45px; /* Set a max height for the images */
    object-fit: contain; /* Ensure images retain aspect ratio */
    padding: 10px;
  }
  .cta-button {
    padding: 12px 24px;
    font-size: 16px;
    font-weight: 500;
    background-color: black;
    color: white;
    border: none;
    border-radius: 20px 0 20px 0;
    cursor: pointer;
    transition: 0.5s ease;
    
  }
  
  .cta-button:hover {
    background-color: black;
    border-radius: 5px 20px 5px 20px;
    
  }
  
  /* Media Query for Smaller Screens */
  @media (max-width: 768px) {
    .hero-section {
      padding: 100px 0;
    }
  
    .hero-content {
      max-width: 90%;
    }
  
    .hero-content h1 {
      font-size: 32px;
    }
  
    .hero-content p {
      font-size: 18px;
      margin-bottom: 20px;
    }
  
    .cta-button {
      padding: 10px 20px;
      font-size: 14px;
    }
  }