.services-section {
    padding: 80px 0;
    text-align: center;
    height: 900px;
  padding-bottom: 0;
  }
  
  .services-section h2 {
    color: black;
    font-size: 32px;
    font-weight: 300;
  }
  
  .services-list {
    display: flex;
    margin-top: 40px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  
  .service {
margin-top: 6rem;
    flex-basis: 40%;
    border-radius: 8px;
    transition: transform 0.5s ease;
    display: inline-block;
  }
  
  .service:hover {
    transform: translateY(-5px);
  }
  
  .service-header {
    display: flex;
    align-items: center;
  
  }
  
  .service-image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-right: 20px;
  }
  
  .service-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
  }
  
  .service-name {
    font-size: 24px;
    font-weight: 500;
    color: black;
  }
  
  .service-role {
    font-size: 18px;
    font-weight: 400;
    color: #555;
  }
  
  .service p {
  
    text-align: left;
    color: #9f9f9f;
    padding: 10px;
    border-radius: 8px;
    font-size: 20px;
    font-weight: 300;
    font-family: Gotham Pro, Helvetica, sans-serif;
  }
  
  /* Media Query for Smaller Screens */
  @media (max-width: 768px) {
    .service {
      flex: 45%;
      padding: 15px;
      width: 300px;
    }
  
    .services-section {  
      overflow-x: auto; 
      white-space: nowrap; 
    }
  
    .service h3 {
      font-size: 1.2rem;
    }
  
    .service p {
      font-size: 14px;
      width: 100%; /* Set width to 100% to fully display text */
      white-space: normal; /* Allow text to wrap */
    }
  
    .buttonLink {
      font-size: 12px;
    }
  }
  
  .video-container {
    position: relative;
    width: 100%;
    height: 200px;
    overflow: hidden;
  }
  
  .custom-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    z-index: 1;
  }
  
  .custom-play-button {
    font-size: 50px;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 50%;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease;
  }
  
  .custom-play-button:hover {
    transform: scale(1.1);
  }
  
  .custom-video-text {
    position: absolute;
    bottom: 20px;
    left: 20px;
    font-size: 18px;
    font-weight: bold;
    color: white;
    z-index: 2;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  }
  
  .video-container iframe {
    width: 100%;
    height: 100%;
    border: none;
    z-index: 0;
    pointer-events: none;
  }
  