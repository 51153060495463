html, body {
    margin: 0; 
    padding: 0; 
    overflow-x: hidden; 
}

.navigation-bar {
    top: 0;
    left: 0;
    height: 71px;
    background-color: rgba(23, 23, 23, 0.95);
    position: fixed;
    width: 100%; 
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px; 
    box-sizing: border-box; 
    z-index: 1000;
    transition: height 0.3s ease-in-out;
}

#logoimg {
    height: 50px;
    width: auto; 
}


.nav-links {
    list-style: none;
    display: flex;
    gap: 20px;
    margin: 0; 
}

.nav-link {
    font-size: 14px;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    transition: color 0.3s ease-in-out;
}

.menu-icon {
    display: none;
    font-size: 24px;
    color: white;
    cursor: pointer;
    z-index: 1100;
}

.services-link {
    position: relative;
}

.dropdown {
    position: absolute;
    background-color: #333;
    list-style: none;
    padding: 10px;
    display: none;
    top: 100%;
    left: 0;
}

.dropdown-link {
    color: white;
    text-decoration: none;
    display: block;
    padding: 8px 12px;
}

.dropdown-link:hover {
    background-color: black;
}

/* Larger screen styling */
@media (min-width: 769px) {
    .navigation-bar{
        padding: 0 8rem;
    }
    .nav-links {
        display: flex;
    }

    .dropdown {
        display: none;
    }

    .services-link:hover .dropdown {
        display: block;
    }
}

/* Media Query for Smaller Screens (768px and below) */
@media (max-width: 768px) {
    .menu-icon {
        display: block;
    }

    .nav-links {
        display: none;
        flex-direction: column;
        background-color: rgba(23, 23, 23, 0.95);
        position: fixed;
        top: 71px;
        left: 0;
        width: 100%;
        height: calc(100vh - 71px); 
        padding-top: 20px;
        z-index: 1;
        align-items: center;
        transform: translateX(100%);
        transition: transform 0.3s ease-in-out;
    }

    .nav-links.active {
        transform: translateX(0);
    }

    .nav-link {
        font-size: 18px;
        padding: 10px 0;
    }

    /* Dropdown for mobile */
    .dropdown {
        position: relative;
        background-color: #333;
        padding: 0;
        display: block;
        margin-top: 10px;
    }
    .nav-links.active {
        transform: translateX(0); 
        display: flex; 
    }
}
