.container {
    display: flex;
    justify-content: space-between;
    align-items: stretch; /* Ensures equal height for both sections */
    padding: 20px;
    gap: 40px; /* Adds spacing between the form and the info */
  }
  
  .form-container {
    flex: 1;
    max-width: 50%; /* Adjust width of the form */
    background-color: #f0f8ff; /* Light blue for left side (form) */
    padding: 20px;
    border-radius: 10px;
  }
  
  .info-container {
    flex: 1;
    max-width: 50%; /* Adjust width of the info section */
    background-color: #fff5f5; /* Light red for right side (info) */
    padding: 20px;
    border-radius: 10px;
  }
  
  .wpcf7-form {
    display: flex;
    flex-direction: column;
  }
  
  .control-row {
    margin-bottom: 15px;
  }
  
  .control-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  textarea.control-input {
    resize: vertical;
  }
  
  .__small-margin {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .btn {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .btn:hover {
    background-color: #0056b3;
  }
  
  /* Info section styling */
  .footer-contact-info {
    padding: 20px;
  }
  
  .foot-info-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .fci-row {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .fcfNums {
    display: flex;
    justify-content: space-between;
  }
  
  .fcfNums-item {
    text-align: center;
  }
  
  .num {
    font-size: 32px;
    font-weight: bold;
    color: #007bff;
  }
  
  /* Media query for smaller screens */
  @media (max-width: 768px) {
    .container {
      flex-direction: column;
      align-items: center;
    }
  
    .form-container, .info-container {
      max-width: 100%;
    }
  }
  