/* Default Styles */
.footer {
  margin-top: 60px;
    background-color: #333;
    color: white;
    padding: 30px 0;
  }
  
  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .footer-contact,
  .footer-social {
    flex-basis: 48%;
  }
  
  .footer h3 {
    font-size: 24px;
    margin-bottom: 20px;
    color: white;
    cursor:none;
  }
  
  .social-icons {
    color: white;
    font-size: 24px;
    margin-right: 15px;
    text-decoration: none;
  }
  
  .social-icons a:hover {
    color: #ff9900;
    background-color: #777;
  }
  
  .footer-copyright {
    text-align: center;
    margin-top: 20px;
  }
  
  .footer-copyright p {
    color: #777;
  }
  
  
  /* Media Query for Smaller Screens */
  @media (max-width: 768px) {
    .footer-content {
      flex-wrap: wrap;
      /* Allow items to wrap on smaller screens */
      justify-content: center;
      /* Center items horizontally */
      text-align: center;
    }
  
    .footer-contact,
    .footer-social {
      flex-basis: 100%;
      /* Set full width for all items on smaller screens */
      margin-bottom: 20px;
      /* Add margin for spacing */
    }
  }