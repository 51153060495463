.success-cases {
    padding: 40px 0;
  }
  
  .wrapper {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .block-heading {
    text-align: center;
    font-size: 36px;
    font-weight: 300;
    margin-bottom: 32px;
    color: #000;
    /* font-family: Gotham Pro, Helvetica, sans-serif; */
    font-family: inherit;
    line-height: 36px;
   
  }
  
  .success-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    flex-wrap: wrap;
    background-color: #fff;
    border-radius: 8px;
    padding: 51px 60px;
  }
  
  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
  
  .success-item .col-xs-12,
  .success-item .col-sm-6,
  .success-item .col-md-6 {
    width: 100%;
  }
  
  .success-item .col-sm-6 {
    width: 50%;
    box-sizing: border-box;
  }
  
  .success-item .heading {
    font-size: 24px;
    font-weight: 600;
    color: #000;
    letter-spacing: -0.44px;
    margin: 0 0 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 95%;
  }
  
  .success-item .subheading {
    font-size: 26px;
    color: #7e7e7e;
    margin-bottom: 20px;
    margin-top: 0;
    font-family: sans-serif;
    line-height: 40px;
    max-width: 486px;
    font-weight: 10;
    letter-spacing: -0.5px;
  }
  
  .success-item .description {
    font-size: 16px;
    color: #999;
    margin-bottom: 15px;
    
  }
  
  .success-item .address {
    font-style: italic;
  }
  
  .success-item .link {
    color: #007BFF;
    font-size: 16px;
    text-decoration: none;
    font-weight: bold;
    margin-top: 15px;
  }
  
  .success-item-img {
    text-align: right;
  }
  .video-bg img{
    border-radius: 10px;
  }
  .success-item-img img {
    max-width: 100%;
    height: auto;
  }
  
  .mob-link-show {
    display: none;
  }
  .browse-more-wrapper {
    text-align: right;
    margin-top: 40px;
    text-decoration: none !important;
    text-transform: uppercase;
    font-size: 18px;
      font-weight: 500;
      line-height: 52px;
      color: #000;
      margin-right: 15px;
      
  }
  
  .all-link {
    font-size: 18px;
    font-weight: bold;
    color: #000;
    text-decoration: none; /* Ensures there's no underline */
    position: relative;
    display: inline-block;
  }
  
  @media (min-width: 768px) {
    .row {
      flex-direction: row;
    }
  
    .success-item .col-sm-6 {
      width: 50%;
    }
  
    .success-item-img {
      text-align: right;
    }
  
    .success-item .link {
      text-align: left;
    }
  }
  